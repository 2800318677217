import React, { useRef, useState } from "react";

import AccountApi, { SocialMedia } from "@/api/Account";
import { WelcomeForm } from "@/api/Settings";

import { useRecorder } from "@/customHooks/useRecorder";
import { useStories } from "@/customHooks/useStories";
import { useWelcomeFormValidation } from "@/features/send-welcome-form/valid";
import { useEvent } from "effector-react";
import { useFormik } from "formik";
import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import dynamic from "next/dynamic";
import Image from "next/future/image";
import Head from "next/head";
import Link from "next/link";
import H5AudioPlayer from "react-h5-audio-player";
import { toast } from "react-hot-toast";
import { AccountApiNS as NS } from "~types/api/account";

import { WelcomeModal } from "@/components/blocks/play/welcome-modal/WelcomeModal";
import {
  closeWelcomeModal,
  openWelcomeModal
} from "@/components/blocks/play/welcome-modal/store";
import WelcomePageRecipeSection from "@/components/blocks/welcome/recipe-section/ui";
import { ContactPlayer } from "@/components/elements/contact-player/ContactPlayer";
import { MapPlayer } from "@/components/elements/map-player/MapPlayer";
import { WelcomePlayer } from "@/components/elements/welcome-player/WelcomePlayer";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography
} from "@mui/material";

import GreenIcon from "~public/icons/Arrow Bottom/Arrow.svg";
import MicIcon from "~public/icons/Mic/Mic.svg";
import PauseIcon from "~public/icons/Pause/Pause.svg";
import PlayIcon from "~public/icons/Play Icon/Play Icon.svg";
import WelcomeFooter from "~public/icons/WelcomeFooter.svg";
import WelcomeTopIcon from "~public/icons/welcomeTopIcon.svg";

const RichText = dynamic(
  () => import("@/shared/ui/rich-text/ui").then(mod => mod.RichText),
  {
    ssr: false
  }
);

const MAX_COUNT = 60 * 10;

const initialValues: WelcomeForm = {
  name: "",
  email: "",
  audio: ""
};

const WelcomePage = ({
  socialMedia,
  splashPage
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { t } = useTranslation("welcome");
  const { useSendWelcomeForm } = useStories();
  const openWelcome = useEvent(openWelcomeModal);
  const closeModal = useEvent(closeWelcomeModal);
  const [success, setSuccess] = useState(false);

  const mapRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const contactPlayerRef = useRef<H5AudioPlayer | null>(null);
  const welcomePlayerRef = useRef<H5AudioPlayer | null>(null);
  const mapPlayerRef = useRef<H5AudioPlayer | null>(null);
  const validationSchema = useWelcomeFormValidation();

  const [
    audioUrl,
    isRecording,
    startRecording,
    stopRecording,
    countdown,
    setAudioURL
  ] = useRecorder(MAX_COUNT);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async values => {
      try {
        const audio = await fetch(audioUrl)
          .then(r => r.blob())
          .then(blob => new File([blob], "audio.mp3", { type: "audio/mpeg" }));

        const formData = new FormData();

        formData.append("audio", audio);
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("subject", "splash_page");
        formData.append("message", "splash_page");

        await useSendWelcomeForm
          .mutateAsync(formData)
          .then(() => {
            closeModal();
            toast.success("Your message has been sent");
            formik.resetForm();
            setAudioURL("");
            setSuccess(true);
          })
          .catch(() => {
            toast.error("Can`t send. Something went wrong");
          });
      } catch (e) {
        toast.error("Can`t send. Something went wrong");
      }
    }
  });

  console.log("REEES", splashPage);

  // useEffect(() => {
  //   AccountApi.getSocialPage()
  //     .then(res => console.log("REEES", res))
  //     .catch(err => console.log("ERR", err));
  // }, []);

  countdown.tick.watch(rs => setCount(rs as any));
  countdown.tick.watch(time => {
    if (time === (0 as any)) {
      stopRecording();
      setCount(MAX_COUNT);
    }
  });

  const [count, setCount] = useState(MAX_COUNT);

  const handleStopRecord = () => {
    stopRecording();
    setCount(MAX_COUNT);
  };

  const toMap = () => {
    if (mapRef?.current) {
      window.scrollTo({
        top: mapRef.current.offsetTop,
        behavior: "smooth"
      });
    }
  };

  const toContact = () => {
    if (contactRef?.current) {
      window.scrollTo({
        top: contactRef.current.offsetTop,
        behavior: "smooth"
      });
    }
  };

  const handleStartRecording = () => {
    mapPlayerRef.current?.audio.current?.pause();
    welcomePlayerRef.current?.audio.current?.pause();
    contactPlayerRef.current?.audio.current?.pause();

    startRecording();
  };

  const handleSend = async () => {
    openWelcome();
  };

  return (
    <>
      <Head>
        <meta name="theme-color" content={splashPage.top_banner_block_color} />
      </Head>
      <Box component={"main"} sx={{ maxWidth: "100vw" }}>
        <Box
          component={"section"}
          sx={{
            width: "100%",
            minHeight: "100vh",
            backgroundColor: splashPage.top_banner_block_color,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
          }}>
          <Box
            sx={{
              paddingTop: "44px",
              paddingLeft: "25px",
              "& > svg": {
                width: { xs: "233px", md: "486px" },
                height: { xs: "52px", md: "108px" }
              }
            }}>
            <WelcomeTopIcon />
          </Box>

          {/*<IconButton*/}
          {/*  sx={{*/}
          {/*    position: "absolute",*/}
          {/*    top: "50%",*/}
          {/*    left: "50%",*/}
          {/*    transform: "translate(-50%, -50%)",*/}
          {/*    border: "2px solid #FFFFFF",*/}
          {/*    width: "163px",*/}
          {/*    height: "163px",*/}
          {/*    "& path": {*/}
          {/*      fill: "#FFFFFF"*/}
          {/*    },*/}
          {/*    "& .MuiButtonBase-root": {}*/}
          {/*  }}>*/}
          {/*  <PlayIcon style={{ fontSize: "58px" }} />*/}
          {/*</IconButton>*/}

          <WelcomePlayer
            ref={welcomePlayerRef}
            contactPlayerRef={contactPlayerRef}
            mapPlayerRef={mapPlayerRef}
            sx={{
              margin: "100px 0"
              // position: "absolute",
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)"
            }}
            color={splashPage.top_banner_player_color}
            place={"top"}
            audioSrc={socialMedia.first_audio ?? ""}
          />

          {/*<RichText />*/}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{ maxWidth: "80%", width: "100%" }}
              dangerouslySetInnerHTML={{
                __html: splashPage.top_banner_reach_text
              }}
            />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}>
            <IconButton
              onClick={toMap}
              sx={{
                // position: "absolute",
                // bottom: 0,
                // left: "50%",
                // transform: "translate(-50%, 0)",
                width: "fit-content",
                height: "fit-content",
                "& path": {
                  fill: splashPage.top_banner_arrow_color
                }
              }}>
              <GreenIcon style={{ fontSize: "71px" }} />
            </IconButton>
          </div>
        </Box>

        {splashPage.show_recipe_block && (
          <WelcomePageRecipeSection
            title={splashPage.recipe_block_title}
            recipes={splashPage.recipes}
            textColor={splashPage.recipe_block_text_color}
            blockColor={splashPage.recipe_block_color}
          />
        )}

        {/*Main section*/}
        <Box
          component={"section"}
          ref={mapRef}
          sx={{
            width: "100%",
            minHeight: { xs: "650px", md: "100vh" },
            backgroundColor: splashPage.main_background_color,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingBottom: { xs: "100px", md: 0 }
            // overflow: "hidden"
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              // transform: "translateY(-70px)",
              position: "relative",
              width: "100%",
              "& img": {
                width: "100%",
                objectFit: { xs: "cover", md: "contain" },
                height: { xs: "364px", md: "auto" },
                maxHeight: { xs: "364px", md: "calc(100vh - 300px)" }
              }
            }}>
            {/*<Image*/}
            {/*  src={"/images/welcome-map.webp"}*/}
            {/*  width={1720}*/}
            {/*  height={968}*/}
            {/*  alt="Map"*/}
            {/*/>*/}
          </Box>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "45px"
            }}>
            <div
              style={{ maxWidth: "80%", width: "100%" }}
              dangerouslySetInnerHTML={{
                __html: splashPage.main_reach_text
              }}
            />
          </div>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <MapPlayer
              ref={mapPlayerRef}
              color={splashPage.main_player_color}
              contactPlayerRef={contactPlayerRef}
              welcomePlayerRef={welcomePlayerRef}
              sx={{ maxWidth: "260px", width: "100%" }}
              audioSrc={socialMedia.second_audio ?? ""}
            />
          </Box>
          <Box
            sx={{
              marginTop: "23px",
              justifyContent: "center",
              display: { xs: "none", md: "flex" },
              "& path": {
                fill: splashPage.main_arrow_color
              }
            }}>
            <IconButton onClick={toContact}>
              <GreenIcon style={{ fontSize: "71px" }} />
            </IconButton>
          </Box>
        </Box>

        <Box
          component={"section"}
          ref={contactRef}
          sx={{
            width: "100%",
            height: { xs: "620px", md: "750px" },
            backgroundColor: splashPage.contact_block_color,
            paddingTop: { xs: "68px", md: "88px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}>
          <Typography
            sx={{
              fontSize: { xs: "40px", md: "60px" },
              marginBottom: { xs: "100px", md: "115px" },
              color: splashPage.contact_head_text_1_color
            }}
            textAlign={"center"}
            fontWeight={"normal"}
            variant={"h2"}>
            {splashPage.contact_head_text_1}{" "}
            <Box
              sx={{ color: splashPage.contact_head_text_2_color }}
              component={"span"}>
              {splashPage.contact_head_text_2}
            </Box>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%"
            }}>
            {!audioUrl && !success && (
              <>
                <Box
                  sx={{
                    position: "relative",
                    marginBottom: { xs: "154px", md: "100px" }
                  }}>
                  <CircularProgress
                    sx={{
                      "&.MuiCircularProgress-root": {
                        color: splashPage.contact_footer_text_color
                      }
                    }}
                    size={123}
                    disableShrink={false}
                    thickness={1}
                    variant={"determinate"}
                    value={100}
                  />
                  <CircularProgress
                    sx={{
                      "&.MuiCircularProgress-root": {
                        color: "#FFFFFF"
                      },
                      position: "absolute",
                      left: 0
                    }}
                    size={123}
                    disableShrink={false}
                    thickness={1}
                    variant={"determinate"}
                    value={100 - (count * 100) / MAX_COUNT}
                  />
                  <IconButton
                    onClick={
                      !audioUrl && isRecording
                        ? handleStopRecord
                        : handleStartRecording
                    }
                    sx={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: 123,
                      height: 123,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      "& path": {
                        fill: splashPage.contact_footer_text_color
                      }
                    }}>
                    <MicIcon style={{ fontSize: "56px" }} />
                    {/*<Typography*/}
                    {/*  sx={{*/}
                    {/*    fontSize: 24*/}
                    {/*  }}>*/}
                    {/*  {new Date((MAX_COUNT - count) * 1000)*/}
                    {/*    .toISOString()*/}
                    {/*    .substring(14, 19)}*/}
                    {/*</Typography>*/}
                  </IconButton>
                </Box>
                <Typography
                  textAlign={"center"}
                  sx={{
                    fontSize: { xs: "24px", md: "40px" },
                    color: splashPage.contact_footer_text_color
                  }}>
                  By leaving a message
                </Typography>
              </>
            )}
            {audioUrl && !success && (
              <Box
                sx={{
                  maxWidth: "260px",
                  width: "100%",
                  "& .rhap_container": {
                    backgroundColor: "transparent",
                    display: "inherit",
                    width: "auto",
                    padding: 0,
                    boxShadow: "none"
                  },

                  "& .rhap_additional-controls": {
                    display: "none"
                  },
                  "& .rhap_volume-controls": {
                    display: "none"
                  },
                  "& .rhap_button-clear": {
                    background: "transparent",
                    outline: "none",
                    border: "none",
                    width: "auto",
                    height: "auto"
                  },
                  "& .rhap_main-controls": {
                    display: "flex",
                    justifyContent: "center"
                  },
                  "& .rhap_main": {
                    display: "flex",
                    flexDirection: "column-reverse",
                    alignItems: "center"
                  },
                  "& .rhap_progress-section": {
                    width: "100%",
                    position: "relative",
                    transform: "translateY(23px)",
                    display: "block"
                  },
                  "& .rhap_progress-indicator": {
                    backgroundColor: "#C1E4FF",
                    height: "2px",
                    width: "auto"
                  },
                  "& .rhap_progress-bar": {
                    height: "2px",
                    width: "100%",
                    position: "relative"
                  },
                  "& .rhap_progress-filled": {
                    height: "2px",
                    backgroundColor: "#42210A",
                    position: "absolute",
                    top: 0,
                    "::after": {
                      content: "''",
                      width: "16px",
                      height: "16px",
                      borderRadius: "50%",
                      backgroundColor: splashPage.contact_footer_text_color,
                      position: "absolute",
                      right: 0,
                      bottom: "50%",
                      transform: "translateY(50%) translateX(100%)"
                    }
                  },
                  "& .rhap_total-time": {
                    position: "absolute",
                    right: 0,
                    top: 0
                  }
                }}>
                <H5AudioPlayer
                  src={audioUrl}
                  showSkipControls={false}
                  showJumpControls={false}
                  customIcons={{
                    play: (
                      <IconButton
                        sx={{
                          border: `2px solid ${splashPage.contact_footer_text_color}`,
                          width: "123px",
                          height: "123px",
                          "& path": {
                            fill: splashPage.contact_footer_text_color
                          },
                          "& .MuiButtonBase-root": {}
                        }}>
                        <PlayIcon style={{ fontSize: "48px" }} />
                      </IconButton>
                    ),
                    pause: (
                      <IconButton
                        sx={{
                          border: `2px solid ${splashPage.contact_footer_text_color}`,
                          width: "123px",
                          height: "123px",
                          "& path": {
                            fill: splashPage.contact_footer_text_color
                          },
                          "& .MuiButtonBase-root": {}
                        }}>
                        <PauseIcon style={{ fontSize: "48px" }} />
                      </IconButton>
                    )
                  }}
                />
                {/*<IconButton*/}
                {/*  sx={{*/}
                {/*    border: "4px solid #42210A",*/}
                {/*    width: "123px",*/}
                {/*    height: "123px",*/}
                {/*    "& path": {*/}
                {/*      fill: "#42210A"*/}
                {/*    },*/}
                {/*    "& .MuiButtonBase-root": {}*/}
                {/*  }}>*/}
                {/*  <PlayIcon style={{ fontSize: "48px" }} />*/}
                {/*</IconButton>*/}
                <Box
                  sx={{
                    marginTop: { xs: "50px", md: "100px" },
                    display: "flex",
                    justifyContent: "space-between"
                  }}>
                  <Button
                    onClick={handleSend}
                    sx={{
                      width: { md: "148px" },
                      marginRight: { md: "46px" },
                      border: `1px solid ${splashPage.contact_footer_text_color}`,
                      color: splashPage.contact_footer_text_color
                    }}
                    variant={"outlined"}>
                    SEND
                  </Button>
                  <Button
                    onClick={() => setAudioURL("")}
                    sx={{
                      width: { md: "148px" },
                      color: "#5CE0B0",
                      backgroundColor: "#42210A !important"
                      // color: "",
                      // backgroundColor: splashPage.contact_block_color
                    }}>
                    DELETE
                  </Button>
                </Box>
              </Box>
            )}
            {success && (
              <>
                <Typography
                  textAlign={"center"}
                  sx={{
                    fontSize: { xs: "24px", md: "40px" },
                    marginBottom: { xs: "145px", md: "160px" },
                    color: splashPage.contact_footer_text_color
                  }}>
                  Message received, we’ll be in touch
                </Typography>
                <Button
                  onClick={() => {
                    setSuccess(false);
                  }}
                  sx={{
                    border: `1px solid ${splashPage.contact_footer_text_color}`,
                    color: splashPage.contact_footer_text_color
                  }}
                  variant={"outlined"}>
                  NEW MESSAGE
                </Button>
              </>
            )}
          </Box>
        </Box>

        {/*Parnters*/}
        <Box
          component={"section"}
          sx={{
            width: "100%",
            height: { xs: "auto", md: "100%" },
            minHeight: { xs: "initial", md: "750px" },
            backgroundColor: splashPage.partner_block_color,
            paddingTop: { xs: "68px", md: "88px" },
            paddingBottom: { xs: "50px", md: "50px" },
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            alignItems: "center"
          }}>
          <Typography
            sx={{
              fontSize: { xs: "40px", md: "60px" },
              marginBottom: { xs: "100px", md: "115px" },
              color: splashPage.partner_text_color
            }}
            textAlign={"center"}
            fontWeight={"normal"}
            variant={"h2"}>
            Partners
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              flexWrap: "wrap",
              marginBottom: { xs: "30px", md: "60px" }
            }}>
            {/*<Box sx={{ marginBottom: { xs: "30px", md: 0 } }}>*/}
            {/*  <Image*/}
            {/*    style={{ objectFit: "contain" }}*/}
            {/*    src={"/images/UitDeKeukenVanMaass.png"}*/}
            {/*    width={260}*/}
            {/*    height={191}*/}
            {/*    alt="UitDeKeukenVanMaass"*/}
            {/*  />*/}
            {/*</Box>*/}
            {/*<Box*/}
            {/*  sx={{*/}
            {/*    marginBottom: { xs: "30px", md: 0 },*/}
            {/*    display: "flex",*/}
            {/*    alignItems: "center",*/}
            {/*    marginX: "160px"*/}
            {/*  }}>*/}
            {/*  <Image*/}
            {/*    style={{ objectFit: "contain" }}*/}
            {/*    src={"/images/Vemaat.png"}*/}
            {/*    width={248}*/}
            {/*    height={44}*/}
            {/*    alt="Vemaat"*/}
            {/*  />*/}
            {/*</Box>*/}
            <Link href={"https://thp.org/"}>
              <a target={"_blank"}>
                <Box sx={{ marginBottom: { xs: "30px", md: 0 } }}>
                  <Image
                    style={{ objectFit: "contain" }}
                    src={"/images/TheZeroHungerProject.png"}
                    width={150}
                    height={125}
                    alt="TheZeroHungerProject"
                  />
                </Box>
              </a>
            </Link>
          </Box>
          <ContactPlayer
            ref={contactPlayerRef}
            welcomePlayerRef={welcomePlayerRef}
            mapPlayerRef={mapPlayerRef}
            sx={{ maxWidth: "260px", width: "100%", marginBottom: "100px" }}
            audioSrc={socialMedia.third_audio ?? ""}
            color={splashPage.partner_player_color}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "45px"
            }}>
            <div
              style={{ maxWidth: "80%", width: "100%" }}
              dangerouslySetInnerHTML={{
                __html: splashPage.partner_reach_text
              }}
            />
          </div>
        </Box>
      </Box>

      {/*Footer */}
      <Box
        component={"footer"}
        sx={{
          width: "100%",
          backgroundColor: "#6ABDAB",
          paddingTop: "40px",
          paddingBottom: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: { xs: "100px", md: "250px" }
        }}>
        <Box
          sx={{
            maxWidth: "500px",
            "& > svg": {
              width: { xs: "233px", md: "486px" },
              height: { xs: "40px", md: "108px" }
            }
          }}>
          <WelcomeFooter />
        </Box>
        <Box
          sx={{
            maxWidth: "315px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            position: "relative"
          }}>
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    display: "flex",*/}
          {/*    flexDirection: "column",*/}
          {/*    alignItems: "center"*/}
          {/*  }}>*/}
          {/*  <Typography*/}
          {/*    sx={{ color: "#A1A1A1", fontSize: "16px", marginBottom: "25px" }}>*/}
          {/*    Social media*/}
          {/*  </Typography>*/}
          {/*  <Box*/}
          {/*    sx={{*/}
          {/*      display: "flex",*/}
          {/*      width: "158px",*/}
          {/*      justifyContent: "space-evenly"*/}
          {/*    }}>*/}
          {/*    <DefaultLink*/}
          {/*      target={"_blank"}*/}
          {/*      href="https://facebook.com/cookachu1">*/}
          {/*      <Facebook style={{ fontSize: 24 }} />*/}
          {/*    </DefaultLink>*/}
          {/*    <DefaultLink*/}
          {/*      target={"_blank"}*/}
          {/*      href="https://instagram.com/cookachu1">*/}
          {/*      <Instagram style={{ fontSize: 24 }} />*/}
          {/*    </DefaultLink>*/}
          {/*    <DefaultLink*/}
          {/*      target={"_blank"}*/}
          {/*      href="https://twitter.com/cookachu1">*/}
          {/*      <Twitter style={{ fontSize: 24 }} />*/}
          {/*    </DefaultLink>*/}
          {/*    <DefaultLink*/}
          {/*      target={"_blank"}*/}
          {/*      href="https://youtube.com/cookachu1">*/}
          {/*      <YouTube style={{ fontSize: 24 }} />*/}
          {/*    </DefaultLink>*/}
          {/*  </Box>*/}
          {/*</Box>*/}

          {/*<Box sx={{ display: "flex", flexDirection: "column" }}>*/}
          {/*  <Typography*/}
          {/*    sx={{ color: "#A1A1A1", fontSize: "16px", marginBottom: "25px" }}>*/}
          {/*    Company*/}
          {/*  </Typography>*/}
          {/*  <Box>*/}
          {/*    <Box*/}
          {/*      sx={{*/}
          {/*        display: "flex"*/}
          {/*      }}>*/}
          {/*      /!*<Link href={"/terms"}>*!/*/}
          {/*      /!*  <a style={{ textDecoration: "none" }}>*!/*/}
          {/*      /!*    <Typography*!/*/}
          {/*      /!*      sx={{*!/*/}
          {/*      /!*        color: "#FFFFFF",*!/*/}
          {/*      /!*        fontSize: "14px",*!/*/}
          {/*      /!*        marginBottom: "13px",*!/*/}
          {/*      /!*        whiteSpace: "nowrap",*!/*/}
          {/*      /!*        marginRight: "20px"*!/*/}
          {/*      /!*      }}>*!/*/}
          {/*      /!*      Terms of Service*!/*/}
          {/*      /!*    </Typography>*!/*/}
          {/*      /!*  </a>*!/*/}
          {/*      /!*</Link>*!/*/}
          {/*      /!*<Link href={"/login"}>*!/*/}
          {/*      /!*  <a style={{ transform: "translateY(-10px)" }}>*!/*/}
          {/*      /!*    <Tooltip title={"Click to login"} placement={"top"}>*!/*/}
          {/*      /!*      <IconButton*!/*/}
          {/*      /!*        sx={{*!/*/}
          {/*      /!*          "& path": {*!/*/}
          {/*      /!*            fill: "white"*!/*/}
          {/*      /!*          }*!/*/}
          {/*      /!*        }}>*!/*/}
          {/*      /!*        <ProfileIcon style={{ fontSize: "26px" }} />*!/*/}
          {/*      /!*      </IconButton>*!/*/}
          {/*      /!*    </Tooltip>*!/*/}
          {/*      /!*  </a>*!/*/}
          {/*      /!*</Link>*!/*/}
          {/*    </Box>*/}
          {/*    /!*<Link href={"/privacy-policy"}>*!/*/}
          {/*    /!*  <a style={{ textDecoration: "none" }}>*!/*/}
          {/*    /!*    <Typography sx={{ color: "#FFFFFF", fontSize: "14px" }}>*!/*/}
          {/*    /!*      Privacy Policy*!/*/}
          {/*    /!*    </Typography>*!/*/}
          {/*    /!*  </a>*!/*/}
          {/*    /!*</Link>*!/*/}
          {/*  </Box>*/}
          {/*</Box>*/}
        </Box>
      </Box>
      <WelcomeModal formik={formik} />
    </>
  );
};

export const getServerSideProps: GetServerSideProps<{
  socialMedia: SocialMedia;
  splashPage: NS.GetSplashPageInfoResponse;
}> = async context => {
  const socialMedia = await AccountApi.getSocialPage();
  const splashPage = await AccountApi.getSplashPageInfo("ear.contact");

  return {
    props: {
      ...(await serverSideTranslations(context.locale as string, [
        "common",
        "homepage",
        "more-drawer",
        "searchpage",
        "recipe"
      ])),
      socialMedia,
      splashPage
    }
  };
};

export default WelcomePage;
