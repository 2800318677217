import React, {useState} from "react";

import PanIcon from "~public/icons/Pan/Pan.svg";

import s from "./style.module.css";
import {Avatar, Box, IconButton, Typography} from "@mui/material";
import {Recipe} from "~types/recipe";
import Arrow from "~public/icons/arr.svg";
import {useRouter} from "next/router";
import {useKeenSlider} from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import clsx from "clsx";
import {SpritesIcon} from "@/shared/ui/sprite-icon/ui";
import Link from "next/link";

type Props = {
  recipes: Recipe[];
  title: string | null;
  textColor: string | null;
  blockColor: string | null;
}

const WelcomePageRecipeSection = ({ recipes, title, blockColor, textColor }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [ref, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    slides: {
      perView: 4
    },
    breakpoints: {
      "(max-width: 1000px)": {
        slides: {
          perView: 2
        }
      },
      "(max-width: 500px)": {
        slides: {
          perView: 1
        }
      }
    }
  })

  return (
    <section style={{ backgroundColor: blockColor || "" }} className={s.section}>
      <Typography
        sx={{
          fontSize: {xs: "40px", md: "60px"},
          marginBottom: {xs: "100px", md: "115px"},
          color: textColor || ""
        }}
        textAlign={"center"}
        fontWeight={"normal"}
        variant={"h2"}>
        {title}
      </Typography>
      <div style={{display: "flex", alignItems: "center"}}>
        <IconButton onClick={(e: any) =>
          e.stopPropagation() || instanceRef.current?.prev()}>
          <Arrow style={{transform: "rotate(180deg)"}} />
        </IconButton>
        <div ref={ref} className={clsx(s.recipes_container, "keen-slider")}>
          {recipes.map((recipe) => (
            <Pan key={recipe.pk} recipe={recipe} />
          ))}
        </div>
        <IconButton sx={{height: "fit"}} onClick={(e: any) =>
          e.stopPropagation() || instanceRef.current?.next()}>
          <Arrow />
        </IconButton>
      </div>
    </section>
  );
};

type PanProps = {
  recipe: Recipe;
}

const Pan = ({ recipe }: PanProps) => {
  const {query, push} = useRouter();

  const handleRedirect = () => {
    const company = query?.company as string;
    const qrname = recipe?.promo?.hashed_id;

    if (company) {
      return `/recipe/promo/${company}/${qrname}`;
    } else if (qrname) {
      return `/recipe/promo/${qrname}`;
    } else {
      return "";
    }
  };

  return (
    <div className={clsx(s.recipe_block, "keen-slider__slide")}>
      <div className={s.pan__container}>
        <PanIcon className={s.pan} />
        <Avatar
          className="avatar"
          sx={{
            position: "absolute",
            width: 55,
            height: 55,
            top: 23,
            left: 20,
            opacity: 0,
            transition: "all 600ms ease-in-out",
            zIndex: -2
          }}>
        </Avatar>
        <IconButton className={s.play__button}>
          <Link legacyBehavior href={handleRedirect()}>
            <a target={"_self"}>
              <SpritesIcon className={s.play} name={"play"} section={"play"} />
            </a>
          </Link>
        </IconButton>
        <Box
          className={s.pan_text}
          sx={{
            position: "relative",
            width: "140px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center"
          }}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 600,
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 4,
              color: "#FFFFFF",
              opacity: 0.7
            }}>
            {recipe.title}
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default WelcomePageRecipeSection;
