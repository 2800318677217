import React, { CSSProperties } from "react";

interface Props {
  className?: string;
  name: string;
  section: string;
  style?: CSSProperties;
}

export const SpritesIcon = ({ section, name, className, style }: Props) => {
  return (
    <svg style={style} className={className}>
      <use xlinkHref={`/sprites/${section}.svg#${name}`} />
    </svg>
  );
};
