import React, { MutableRefObject, forwardRef } from "react";

import H5AudioPlayer from "react-h5-audio-player";

import { Box, IconButton, SxProps, Theme } from "@mui/material";

import PauseIcon from "~public/icons/Pause/Pause.svg";
import PlayIcon from "~public/icons/Play Icon/Play Icon.svg";

interface Props {
  audioSrc: string;
  place?: "top";
  sx?: SxProps<Theme>;
  welcomePlayerRef: MutableRefObject<H5AudioPlayer | null>;
  mapPlayerRef: MutableRefObject<H5AudioPlayer | null>;
  color?: string;
}

export const ContactPlayer = forwardRef<H5AudioPlayer, Props>(
  function ContactPlayer(
    { audioSrc, sx, place, welcomePlayerRef, mapPlayerRef, color },
    ref
  ) {
    const stopOther = () => {
      mapPlayerRef.current?.audio.current?.pause();
      welcomePlayerRef.current?.audio.current?.pause();
    };

    return (
      <Box
        sx={{
          ...sx,
          "& .rhap_container": {
            backgroundColor: "transparent",
            display: "inherit",
            width: "auto",
            padding: 0,
            boxShadow: "none"
          },
          "& .rhap_play-pause-button": {
          },
          "& .rhap_additional-controls": {
            display: "none"
          },
          "& .rhap_volume-controls": {
            display: "none"
          },
          "& .rhap_button-clear": {
            background: "transparent",
            outline: "none",
            border: "none",
            width: "auto",
            height: "auto"
          },
          "& .rhap_main-controls": {
            display: "flex",
            justifyContent: "center"
          },
          "& .rhap_main": {
            display: "flex",
            flexDirection: "column-reverse",
            alignItems: "center"
          },
          "& .rhap_progress-section": {
            width: "100%",
            position: "relative",
            transform: "translateY(23px)",
            display: place === "top" ? "none" : "block"
          },
          "& .rhap_progress-indicator": {
            backgroundColor: color ? color : "#42210A81",
            height: "2px",
            width: "auto"
          },
          "& .rhap_progress-bar": {
            height: "2px",
            width: "100%",
            position: "relative"
          },
          "& .rhap_progress-filled": {
            height: "2px",
            backgroundColor: color ? color : "#42210A",
            position: "absolute",
            top: 0,
            "::after": {
              content: "''",
              width: "16px",
              height: "16px",
              borderRadius: "50%",
              backgroundColor: color ? color : "#42210A",
              position: "absolute",
              right: 0,
              bottom: "50%",
              transform: "translateY(50%) translateX(100%)"
            }
          },
          "& .rhap_total-time": {
            position: "absolute",
            right: 0,
            top: 0
          }
        }}>
        <H5AudioPlayer
          src={audioSrc}
          ref={ref}
          showSkipControls={false}
          showJumpControls={false}
          customIcons={{
            play: (
              <IconButton
                onClick={stopOther}
                sx={{
                  border: color ? `2px solid ${color}` : "2px solid #42210A",
                  width: { xs: "100px", md: "123px" },
                  height: { xs: "100px", md: "123px" },
                  "& path": {
                    fill: color ? color : "#42210A"
                  },
                  "& .MuiButtonBase-root": {}
                }}>
                <PlayIcon style={{ fontSize: "48px" }} />
              </IconButton>
            ),
            pause: (
              <IconButton
                sx={{
                  border: color ? `2px solid ${color}` : "2px solid #42210A",
                  width: { xs: "100px", md: "123px" },
                  height: { xs: "100px", md: "123px" },
                  "& path": {
                    fill: color ? color : "#42210A"
                  },
                  "& .MuiButtonBase-root": {}
                }}>
                <PauseIcon style={{ fontSize: "48px" }} />
              </IconButton>
            )
          }}
        />
      </Box>
    );
  }
);
